<template>
<div class="body">
  <div id="support">
    <div class="searchBackground">
      <div class="mask"></div>
      <img src="@/assets/img/keyboard.jpg" alt="">
      <div class="searchBox">
        <h1>歡迎使用Finger支持中心</h1>
        <div class="searchInput">
          <input type="text">
          <div class="btnSearch">
            <svg-icon name="search" color="#5b5b5b"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="lineTwo">
      <div class="lineBox">
        <h1>選擇您的角色快速查找問題</h1>
        <div class="lineBoxItem">
          <div class="btn"><img src="../../assets/img/service/solo.png"><div class="mask"></div><div class="text"><h1>個人用戶</h1><p>我想申請Finger Taring app使用</p></div></div>
          <div class="btn"><img src="../../assets/img/service/company.png"><div class="mask"></div><div class="text"><h1>機構用戶</h1><p>我想申請Finger Taring app使用</p></div></div>
          <div class="btn"><img src="../../assets/img/service/bank.png"><div class="mask"></div><div class="text"><h1>其他金融機構</h1><p>我想申請Finger Taring app使用</p></div></div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'

export default {
  name:'Support',
  components: {
    SvgIcon

  },
  data() {
    return {
      email: ""
    }
  },
  methods:{},
}
</script>

<style scoped>
.body{
  padding-top: 50px;
  background: #000;
}
#support{
  position: relative;
  width: 100vw;
  height: 100%;
  background: radial-gradient(#e5e5e5,#ffffff);
  overflow: scroll;
}
.searchBackground{
  position: relative;
  height: 420px;
  width: 100vw;
  overflow: hidden;
}
img{
  width: 100%;
}
.mask{
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .2;
  z-index: 1;
}
.searchBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  text-align: center;
  color: #fff;
}
input{
  width: 600px;
  height: 50px;
  font-size: 24px;
  border-radius: 10px;
  border: none;
  padding-left: 60px;
  box-sizing: border-box;
  margin-top: 20px;
}
.searchInput{
  position: relative;
  width: 600px;
  height: 50px;
}
.btnSearch{
  position: absolute;
  left: 0;
  height: 50px;
  width: 60px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.lineTwo{
  width: 100vw;
  height: 360px;
  border-bottom: 2px solid #9f9e9e;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lineBox{
  height: 260px;
  width: 68%;
  text-align: center;
}
.lineBoxItem{
  height: 260px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.btn{
  position: relative;
  width: 280px;
  height: 180px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
}
.mask{
  position: absolute;
  background: #000;
  opacity: .6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.text{
  position: absolute;
  z-index: 9;
  color: #fff;
  bottom: 38px;
  left: 20px;
}
.title h1{
  font-size: 60px;
}
img{
  height: 100%;
}
</style>
